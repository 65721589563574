import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import LocalAreaImgDesktop from "../../../images/PropertyDetails/video-bg-desktop2.jpg"
import LocalAreaImgTablet from "../../../images/PropertyDetails/video-bg-tab2.jpg"
import ReactMarkdown from "react-markdown/with-html"
import LocalAreaImgMobile from "../../../images/PropertyDetails/video-bg-mobile2.jpg"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./LocalArea.scss"
import PlayVideo from "../../Play/PlayVideo"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform"
import getVideoId from "get-video-id"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
  fadeInFromLeft,
  imageZoom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const SINGLE_AREA = gql`
  query GetAreaDetails($Area_Mapping_Postcode: String!) {
    areaguides(
      where: { Area_Mapping_Postcode_contains: $Area_Mapping_Postcode }
    ) {
      id
      Name
      Area_Mapping_Postcode
      URL
      Youtube_Video_URL
      Select_Areaguide_Location
      Intro_Content
      Tile_Image {
        alternativeText
        url
      }
      imagetransforms
    }
  }
`
function LocalArea(props) {
  const { loading, error, data } = useQuery(SINGLE_AREA, {
    variables: { Area_Mapping_Postcode: props.postcode },
  })
  const [isPlay, setPlay] = useState(false)
  //property description
  let text_truncate = function (str, length, ending) {
    if (length == null) {
      length = 230
    }
    if (ending == null) {
      ending = "..."
    }
    if (str && str.length > length) {
      return str.substring(0, length - ending.length) + ending
    } else {
      return str
    }
  }

  return (
    <React.Fragment>
      <div>
        {data?.areaguides?.map((areaguide, key) => {
          const videoid =
            areaguide?.Youtube_Video_URL &&
            getVideoId(areaguide?.Youtube_Video_URL)
          let processedImages = JSON.stringify({})
          if (areaguide?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = areaguide.imagetransforms.Tile_Image_Transforms
          }
          if (
            areaguide.Area_Mapping_Postcode?.includes(
              props.postcodefull?.slice(0, -2)
            )
          ) {
            return (
              <InView {...inViewOptions}>
                {({ ref, inView }) => (
                  <motion.section
                    className="local-area"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                  >
                    <Container>
                      <Row>
                        <Col lg={6} className="pl-lg-4 order-xl-2 order-lg-2">
                          <motion.div
                            variants={imageZoom}
                            className="local-area-video"
                          >
                            <ImageTransform
                              imagesources={areaguide.Tile_Image.url}
                              renderer="srcSet"
                              imagename="areaguides.Tile_Image.mappingguide"
                              attr={{
                                alt: areaguide.Tile_Image.alternativeText,
                              }}
                              imagetransformresult={processedImages}
                              id={areaguide.id}
                            />
                            {areaguide.Youtube_Video_URL != null &&
                              areaguide.Youtube_Video_URL != "" && (
                                <strong
                                  className="video-arrow video-arrow-localarea"
                                  onClick={e => {
                                    setPlay(true)
                                  }}
                                >
                                  <i className="icon-video-white"></i>
                                </strong>
                              )}
                          </motion.div>
                        </Col>
                        <Col lg={6} className="order-xl-1 order-lg-1">
                          <div className="local-area-content">
                            <motion.span
                              variants={fadeInFromLeft}
                              class="small-font-heading"
                            >
                              The Local Area
                            </motion.span>
                            <motion.h2
                              variants={fadeInFromLeft}
                              className="sub-heading-localarea"
                            >
                              {areaguide.Name} Area Guide
                            </motion.h2>
                            <motion.div variants={fadeInFromLeft}>
                              {" "}
                              <ReactMarkdown
                                source={areaguide.Intro_Content}
                                escapeHtml={false}
                              />
                            </motion.div>
                            <motion.a
                              variants={fadeInFromLeft}
                              href={`/area-guides/${areaguide.Select_Areaguide_Location.toLowerCase()}-area-guides/${
                                areaguide.URL
                              }`}
                              class="read-more custom-link"
                            >
                              <i class="icon-plus"></i>
                              Learn more
                            </motion.a>
                          </div>
                        </Col>
                      </Row>
                      {areaguide?.Youtube_Video_URL != null &&
                        areaguide?.Youtube_Video_URL != "" && (
                          <PlayVideo
                            isOpen={isPlay}
                            isCloseFunction={setPlay}
                            videoId={areaguide.Youtube_Video_URL}
                            isAutoPlay={1}
                          />
                        )}
                    </Container>
                  </motion.section>
                )}
              </InView>
            )
          }
          // else if (key === 0 && !areaguide.Area_Mapping_Postcode?.includes(props.postcodefull?.slice(0, -2))){
          //   return (
          //     <InView {...inViewOptions}>
          //       {({ ref, inView }) => (
          //         <motion.section
          //           className="local-area"
          //           ref={ref}
          //           initial="hidden"
          //           animate={inView ? "visible" : "hidden"}
          //         >
          //           <Container>
          //             <Row>
          //               <Col lg={6} className="pl-lg-4 order-xl-2 order-lg-2">
          //                 <motion.div variants={imageZoom} className="local-area-video">
          //                   <ImageTransform imagesources={areaguide.Tile_Image.url} renderer="srcSet" imagename="areaguides.Tile_Image.mappingguide" attr={{ alt: areaguide.Tile_Image.alternativeText }} imagetransformresult={processedImages} id={areaguide.id} />
          //                   {areaguide.Embed_Video_URL != null && videoid && areaguide.Embed_Video_URL != '' &&
          //                     <strong className="video-arrow video-arrow-localarea" onClick={e => { setPlay(true) }}><i className="icon-video-white"></i></strong>
          //                   }
          //                 </motion.div>
          //               </Col>
          //               <Col lg={6} className="order-xl-1 order-lg-1">
          //                 <div className="local-area-content">
          //                   <motion.span variants={fadeInFromLeft} class="small-font-heading">The Local Area</motion.span>
          //                   <motion.h2 variants={fadeInFromLeft} className="sub-heading-localarea">
          //                     {areaguide.Name} Area Guide
          //                   </motion.h2>
          //                   <motion.div variants={fadeInFromLeft}> <ReactMarkdown source={text_truncate(areaguide.Intro_Content)} escapeHtml={false} /></motion.div>
          //                   <motion.a variants={fadeInFromLeft} href={`/area-guides/${areaguide.Select_Areaguide_Location.toLowerCase()}-area-guides/${areaguide.URL}`} class="read-more"><span class="btn-text-link">
          //                     Read more</span>
          //                   </motion.a>
          //                 </div>
          //               </Col>
          //             </Row>
          //             {areaguide?.Embed_Video_URL != null && videoid && areaguide?.Embed_Video_URL != '' &&
          //               <PlayVideo
          //                 isOpen={isPlay}
          //                 isCloseFunction={setPlay}
          //                 videoId={areaguide.Embed_Video_URL}
          //                 isAutoPlay={1}
          //               />
          //             }
          //           </Container>

          //         </motion.section>
          //       )}
          //     </InView>
          //   )
          // }
        })}
      </div>
    </React.Fragment>
  )
}

export default LocalArea
