import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import LocalAreaImgDesktop from "../../../images/PropertyDetails/video-bg-mobile2.jpg";
import LocalAreaImgTablet from "../../../images/PropertyDetails/video-bg-mobile2.jpg";
import LocalAreaImgMobile from "../../../images/PropertyDetails/video-bg-mobile2.jpg";
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap";
import "./PropertyCalculator.scss";

const PropertyCalculator = (props) => {
  
  const [isMortage,setIsMortage] = useState(true);
  const morgateRef = useRef(null);
  const stampRef = useRef(null);

  useEffect(()=>{
    morgateRef.current.onclick = () =>{
      setIsMortage(true)
    }
    stampRef.current.onclick = () => {
      setIsMortage(false)
    }
  },[])

  return (
    <React.Fragment>
    <section className="property-calculator">
      <Container>
        <Row>
          <Col sm={12}>
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeIn" duration={0.5}>
            <div className="property-calc-main-head"><h2 className="d-xl-none">Property Calculator</h2></div>
            <div className="custom-tab-block">
             <div className="custom-tab-pane d-xl-none"> 
              <ul>
                <li ref={morgateRef} className={isMortage ? "activate-tab tab-link" : "tab-link"} ><span>Mortgage Calculator</span></li>
                <li ref={stampRef} className={!isMortage ? "activate-tab tab-link" : "tab-link"} ><span>Stamp Duty</span></li>
              </ul>
             </div>
              <div className="tab-data">
                <div className={isMortage ? "tab-active tab-data-body tab-body-col-1" : "tab-data-body tab-body-col-1"}>
                 <h3 className="d-none d-xl-block">Mortgage Calculator</h3>
                 <p>A quick and easy way to calculate your monthly mortgage payments. Simply enter the property price, the amount or percentage of your deposit, the term over which you intend to pay it off and the interest rate.</p>
                 <div className="form-property-calculator">
                 <Form>
                  <div className="form-col">
                  <Form.Group>
                    <Form.Label>Purchase Price</Form.Label>
                    <div className="form-addon-left form-border">
                    <Form.Control type="number"  placeholder="1575000" /><span>&#163;</span>
                    </div>
                  </Form.Group>
                  
                  <Form.Group>
                    <Form.Label>Annual Interest</Form.Label>
                    <div className="form-addon-right form-border">
                    <Form.Control type="number"  placeholder="2.4"/><span>%</span>
                    </div>
                  </Form.Group>
                  </div>
                  <div className="form-col">
                  {/* <Form.Group>
                    <Form.Label>Deposit</Form.Label>
                    <div className="form-addon-left form-border">
                    <Form.Control type="number"  placeholder="15750"/><span>&#163;</span>
                    </div>
                  </Form.Group> */}
                  
                  <Form.Group>
                    <Form.Label>Repayment Period</Form.Label>
                    <div className="form-addon-right form-border">
                    <Form.Control type="number"  placeholder="25" /><span>Years</span>
                    </div>
                  </Form.Group>
                  </div>
                  <div className="form-col">
                  <Form.Group>
                    <Form.Label>Monthly Repayments</Form.Label>
                    <span className="mrp-amount">&#163;6,917</span>
                  </Form.Group>
                  </div> 
                  <p className="form-bottom-note">
                  Need more info? See our <Link href="/property-services/buy/buying-guide" className="btn-text-link">mortgage guide</Link> and <Link href="/property-services/buy/mortgage-calculator" className="btn-text-link">calculators</Link>
                  </p>
                </Form>
                 </div>
                </div>
                <div className={!isMortage ? " tab-active tab-data-body tab-body-col-2" : "tab-data-body tab-body-col-2"}>
                <h3 className="d-none d-xl-block">Stamp Duty Calculator</h3>
                <p>Find out how much you will have to pay by using our Stamp Duty Calculator.</p>
                <div className="form-property-calculator">
                 <Form>
                  <div className="form-col">
                  <Form.Group>
                    <Form.Label>Purchase Price</Form.Label>
                    <div className="form-addon-left form-border">
                    <Form.Control type="number"  placeholder="1575000" /><span>£</span>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="I’m buying my first home or next home"/>
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox2">
                    <Form.Check type="checkbox" label="I’m buying an additional property or second home"/>
                  </Form.Group>
                  </div>
                  <div className="form-col">
                  <Form.Group className="stamp-duty-field">
                    <Form.Label>Stamp Duty:</Form.Label>
                    <span className="mrp-amount">&#163;30,000</span>
                  </Form.Group>
                  </div> 
                </Form>
                 </div>
                </div>
              </div>
            </div>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </section>
    </React.Fragment>
  );
};

export default PropertyCalculator;
