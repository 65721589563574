import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark,} from "react-bootstrap";
import "./ButtonGroupFooter.scss";


const ButtonGroupFooter = (props) => {
  
  return (
    <React.Fragment>
     <section className="btn-group-footer d-xl-none">
        <div className="btn-group-footer-block">
          <Link to="#" className="btn btn-yellow">Book a viewing</Link>
          <Link to="#" className="btn btn-dark">Call Office</Link>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ButtonGroupFooter;
