import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./RelatedProperty.scss"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import relatedPropImg1Desk from "../../../images/PropertyDetails/related-prop-img1.jpg"
import relatedPropImg1Tab from "../../../images/PropertyDetails/related-prop-img1.jpg"
import relatedPropImg1Mob from "../../../images/PropertyDetails/related-prop-img1.jpg"

import relatedPropImg2Desk from "../../../images/PropertyDetails/related-prop-img2.jpg"
import relatedPropImg2Tab from "../../../images/PropertyDetails/related-prop-img2.jpg"
import relatedPropImg2Mob from "../../../images/PropertyDetails/related-prop-img2.jpg"

import relatedPropImg3Desk from "../../../images/PropertyDetails/related-prop-img3.jpg"
import relatedPropImg3Tab from "../../../images/PropertyDetails/related-prop-img3.jpg"
import relatedPropImg3Mob from "../../../images/PropertyDetails/related-prop-img3.jpg"

import enquiryImgDesk1 from "../../../images/enquiry-img2.png"
import enquiryImgTab1 from "../../../images/enquiry-img2.png"
import enquiryImgMob1 from "../../../images/enquiry-img2.png"

import enquiryImgDesk2 from "../../../images/enquiry-img4.png"
import enquiryImgTab2 from "../../../images/enquiry-img4.png"
import enquiryImgMob2 from "../../../images/enquiry-img4.png"

import enquiryImgDesk3 from "../../../images/enquiry-img3.png"
import enquiryImgTab3 from "../../../images/enquiry-img3.png"
import enquiryImgMob3 from "../../../images/enquiry-img3.png"
import axios from "axios"
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform"
import { empty } from "apollo-boost"
import { isIOS, isMobile } from "react-device-detect"
import GetOffice from "../../getoffice"
import GetImageCount from "../../getpropertyimagecount"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
  fadeInFromLeft,
  imageZoom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const RelatedProperty = props => {
  const [propItems, setPropItems] = useState([])
  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)
      if (data && data.length > 0) {
        setPropItems(data)
      }
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    let url =
      process.env.GATSBY_STRAPI_SRC +
      "/stb-lists/item/Property-details?pid=" +
      props.id
    getitems(url)
    //console.log('propItems',url);
  }, [])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1700 },
      items: 3.2,
      partialVisibilityGutter: 0,
    },
    desktop: {
      breakpoint: { max: 1699, min: 1024 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }
  //console.log('propItems',propItems)
  //if(propItems && propItems.length > 0 ) {
  return (
    <div>
      {propItems.length > 0 ? (
        <React.Fragment>
          <InView {...inViewOptions}>
            {({ ref, inView }) => (
              <motion.section
                className="related-properties"
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
              >
                <Container>
                  <Row>
                    <Col sm={12}>
                      <motion.h2 variants={fadeInFromLeft}>
                        Any of the following take your fancy instead?
                      </motion.h2>
                      <motion.p
                        variants={fadeInFromLeft}
                        className="related-prop-intro"
                      >
                        Other properties that may interest you.
                      </motion.p>
                      <Carousel
                        centerMode={false}
                        responsive={responsive}
                        showDots={true}
                        draggable={true}
                        autoPlay={false}
                        swipeable={true}
                        infinite={true}
                        removeArrowOnDeviceType={[
                          "tablet",
                          "mobile",
                          "desktop",
                        ]}
                      >
                        {propItems &&
                          propItems.map((item, key) => {
                            let processedImages = JSON.stringify({})
                            if (item?.imagetransforms?.images_Transforms) {
                              processedImages =
                                item.imagetransforms.images_Transforms
                            }
                            var checkptype =
                              item.search_type === "sales"
                                ? "/property-for-sale/"
                                : "/property-to-rent/"
                            return (
                              <motion.div
                                variants={delayItemsFromBottom}
                                key={key}
                                custom={key}
                                className="item"
                              >
                                <div className="related-prop-img-block">
                                  <a
                                    href={`${checkptype}${item.slug}-${item.id}`}
                                  >
                                    <ImageTransform
                                      imagesources={item.images[0].url}
                                      renderer="srcSet"
                                      imagename="property.images.similarpropertytile"
                                      attr={{
                                        alt:
                                          item.display_address +
                                          " -  Location Location",
                                      }}
                                      imagetransformresult={processedImages}
                                      id={item.id}
                                      testparam={true}
                                    />
                                  </a>
                                  <GetImageCount id={item.id} />
                                </div>

                                <div className="related-prop-content">
                                  <h2>
                                    <a
                                      href={`${checkptype}${item.slug}-${item.id}`}
                                      className="btn-text-link"
                                    >
                                      {item.display_address}
                                    </a>
                                  </h2>
                                  <p className="amount">
                                    {"£" + item.price.toLocaleString()}
                                  </p>
                                  <p className="description">{item.title}</p>
                                  <div class="list-enquiry enquiry">
                                    <GetOffice
                                      phone={
                                        item.office_details &&
                                        item.office_details.telephone
                                      }
                                    />
                                    <p class="enquiry-text">
                                      <span>
                                        <a
                                          href={`${checkptype}${item.slug}-${item.id}/?id=booking`}
                                          className="border-bottom-yellow"
                                        >
                                          Book a Viewing{" "}
                                        </a>{" "}
                                        {item.office_details.telephone &&
                                    <>
                                     or call{" "}
                                    <a
                                      className="border-bottom-yellow-slide"
                                      href={`tel:${item.office_details.telephone}`}
                                    >
                                      {item.office_details.telephone}
                                    </a></>
                                    }
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </motion.div>
                            )
                          })}
                      </Carousel>
                    </Col>
                  </Row>
                </Container>
              </motion.section>
            )}
          </InView>
        </React.Fragment>
      ) : null}
    </div>
  )
  //}
}

export default RelatedProperty
