import React,{useEffect, useState} from "react";
import Header from "../../Components/Header/Header";
// import Search from "../../Components/Search/Search";
import Footer from "../../Components/Footer/Footer";
import PropertyDetailsBanner from "../../Components/PropertyDetails/PropertyDetailsBanner/PropertyDetailsBanner";
import KeyFeatures from "../../Components/PropertyDetails/KeyFeatures/KeyFeatures";
import LocalArea from "../../Components/PropertyDetails/LocalArea/LocalArea";
import PropertyCalculator from "../../Components/PropertyDetails/PropertyCalculator/PropertyCalculator";
import RelatedProperty from "../../Components/PropertyDetails/RelatedProperty/RelatedProperty";
import Enquiry from "../../Components/PropertyDetails/Enquiry/Enquiry";
import ButtonGroupFooter from "../../Components/PropertyDetails/ButtonGroupFooter/ButtonGroupFooter";

const PropertyDetails = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])

  return (
    <React.Fragment>
      <div className={scroll ? "menu-fixed menu-scroll" : "menu-fixed"}><Header/></div>
      <PropertyDetailsBanner/>
      <KeyFeatures/>
      <LocalArea/>
      <PropertyCalculator/>
      <RelatedProperty/>
      <Enquiry/>
      <div className="prop-details-footer"><Footer/></div>
      <ButtonGroupFooter/>
    </React.Fragment>
    );
};

export default PropertyDetails;
