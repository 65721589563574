import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import videoEnquiryImgDesktop from "../../../images/PropertyDetails/enquiry-video-img-desktop.jpg";
import videoEnquiryImgTablet from "../../../images/PropertyDetails/enquiry-video-img-tab.jpg";
import videoEnquiryImgMobile from "../../../images/PropertyDetails/enquiry-video-img.jpg";
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap";
import "./Enquiry.scss";
import PlayVideo from '../../Play/PlayVideo';
import { isIOS, isMobile } from "react-device-detect";
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
  fadeInFromLeft,
  imageZoom
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const Enquiry = (props) => {
  const bookClick = (value) =>{
    props.bookClick(value)
  } 
  const bookmobileClick = (value) =>{
    props.bookmobileClick(value)
  } 
  const [isPlay,setPlay] = useState(false);
 
  return (
    <React.Fragment>
             <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="prop-details-enquiry"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
          >
        <div className="property-enquiry-grid">
        {/* <div className="video-block-enquiry">
        <picture>
          <source
            media="(min-width:992px)"
            srcSet={videoEnquiryImgDesktop}
          />
          <source
            media="(min-width:768px)"
            srcSet={videoEnquiryImgTablet}
          />
          <img src={videoEnquiryImgMobile} alt="" />
       </picture>
       
        <strong href="#" class="video-arrow" onClick = { e => {setPlay(true)}}><i class="icon-video-white"></i></strong>
        </div> */}
        <motion.div variants={fadeInFromLeft} className="prop-details-enquiry-content">
          <h2>Interested in viewing or enquiring about this property? Let’s get started.</h2>
          {isMobile?
          <a href="javascript:;" onClick={()=>bookmobileClick("BookaViewing")} className="btn btn-outline">Enquire</a>
          :
          <a href="javascript:;" onClick={()=>bookClick("BookaViewing")} className="btn btn-outline">Enquire</a>
          }
        </motion.div>
        </div>
        <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId="jxqVA8vMLnI"
              isAutoPlay={1}
            />
        </motion.section>
      )}
    </InView>
    </React.Fragment>
  );
};

export default Enquiry;
