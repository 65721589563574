import { Link } from "@StarberryUtils";
import React, { useState } from "react";
import BannerImgDesk from "../../../images/PropertyDetails/banner-bg-desktop.jpg"
import BannerImgTab from "../../../images/PropertyDetails/banner-bg-tab.jpg"
import BannerImgMob from "../../../images/PropertyDetails/banner-bg.jpg"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap";
import "./PropertyDetailsBanner.scss";
import PlayVideo from '../../Play/PlayVideo';

const PropertyDetailsBanner = (props) => {

  const [isPlay,setPlay] = useState(false);

  return (
    <React.Fragment>
      <section className="property-details-banner">
      <div className="property-banner-wrapper">
        <picture>
          <source
            media="(min-width:992px)"
            srcSet={BannerImgDesk}
          />
          <source
            media="(min-width:768px)"
            srcSet={BannerImgTab}
          />
          <img src={BannerImgMob} alt="" />
        </picture>
      </div>
      <div className="property-banner-content">
        <Container>
        <a href="#" className="banner-arrow" onClick = { e => {setPlay(true)}}><i className="icon-video-white"></i></a>
        <h1><i className="icon-angle-left d-none d-xl-block"></i>Dumont Road, London, N16</h1>
        <p className="price-name">OIEO £1,575,000</p>
        <div className="banner-list-content">
          <ul>
            <li className="bedroom">
              <i className="icon-bed"></i>
              <p>5 Bedrooms</p>
            </li>
            <li className="Bathrooms">
              <i className="icon-bathroom"></i>
              <p>3 Bathrooms</p>
            </li>
            <li className="garden">
              <i className="icon-garden"></i>
              <p>Private Garden</p>
            </li>
            <li className="chainfree">
              <i className="icon-chainfree"></i>
              <p>Chain Free</p>
            </li>
          </ul>
        </div>
        <div className="d-none d-xl-block">
          <div className="prop-banner-btn-group ">
            <Link href="#" className="btn btn-yellow">Book a Viewing</Link> 
            <Link href="#" className="btn btn-outline">More Information</Link> 
            </div>
        </div> 
        </Container>
      </div>
      <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId="JFqiSr9A-Go"
              isAutoPlay={1}
            />

      </section>
    </React.Fragment>
  );
};

export default PropertyDetailsBanner;
