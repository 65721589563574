import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import keyFeaturesImgDesktop from "../../../images/PropertyDetails/video-bg-desktop.jpg";
import keyFeaturesImgTablet from "../../../images/PropertyDetails/video-bg-tab.jpg";
import keyFeaturesImgMobile from "../../../images/PropertyDetails/video-bg-mobile.jpg";
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap";
import "./KeyFeatures.scss";
import PlayVideo from '../../Play/PlayVideo';

const KeyFeatures = (props) => {

  const [isPlay,setPlay] = useState(false);

  const footer = () =>{
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  };

  const header = () =>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
 
  return (
    <React.Fragment>
      <section className="prop-details-keyfetures">
        <div className="keyfetures">
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInLeft' >
          <span className="small-font-heading">Key Features</span>
          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInLeft' ><h2 className="sub-heading-keyfeatures">Incredibly designed and offering a wealth of chic original character.</h2></ScrollAnimation>
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInLeft' >
          <ul>
            <li>
              <i className="icon-check-big d-md-none d-xl-block"></i><i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i> <span>Secure entry, concierge and lift</span>
            </li>
            <li>
              <i className="icon-check-big d-md-none d-xl-block"></i><i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i> <span>Expansive 46' reception with floor to ceiling windows</span>
            </li>
            <li>
              <i className="icon-check-big d-md-none d-xl-block"></i><i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i> <span>Stylish open-plan kitchen</span>
            </li>
            <li>
              <i className="icon-check-big d-md-none d-xl-block"></i><i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i> <span>En suite main bedroom</span>
            </li>
            <li>
              <i className="icon-check-big d-md-none d-xl-block"></i><i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i> <span>2nd bedroom with fitted wardrobes</span>
            </li>
            <li>
              <i className="icon-check-big d-md-none d-xl-block"></i><i className="icon-check-big-tilted d-none d-md-block d-xl-none"></i> <span>Chic walk-in wardrobe</span>
            </li>
          </ul>
          </ScrollAnimation>
          <div className="button-group-scroll-buttons d-none d-xl-block">
            <div className="button-scroll-inner">
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInLeft' ><strong to="#" className="btn-round" onClick={header}><i className="icon-angle-round-up"></i>Introduction</strong></ScrollAnimation>
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeInLeft' ><strong to="#" className="btn-round" onClick={footer}><i className="icon-angle-round-down"></i>Details</strong></ScrollAnimation>
              </div>
          </div>
        </div>
        <div className="keyfeatures-banner">
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn='fadeIn' >
          <strong href="#" className="video-arrow" onClick = { e => {setPlay(true)}}><i className="icon-video-white"></i></strong>
          <picture>
            <source
              media="(min-width:992px)"
              srcSet={keyFeaturesImgDesktop}
            />
            <source
              media="(min-width:768px)"
              srcSet={keyFeaturesImgTablet}
            />
            
            <img src={keyFeaturesImgMobile} alt="" />
            
          </picture>
          </ScrollAnimation>
        </div>
        <PlayVideo
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId="9dDiYS7GwXM"
              isAutoPlay={1}
            />
      </section>
    </React.Fragment>
  );
};

export default KeyFeatures;
